import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import LanguageSwitcher from '../../components/getpit/layout/language-switcher'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout
      className={classNames({
        cui__layout__contentMaxWidth: true,
        cui__layout__appMaxWidth: true,
        cui__layout__grayBackground: true,
        cui__layout__squaredBorders: true,
        cui__layout__cardsShadow: true,
        cui__layout__borderless: false,
      })}
    >
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
        >
          <div
            className={classNames(`${style.topbar}`, {
              [style.topbarGray]: isGrayTopbar,
            })}
          >
            <div className={style.logoContainer}>
              <div className={style.logo}>
                <img src="resources/images/logo.svg" className="mr-2" alt="Clean UI" />
                <div className={style.name}>{logo}</div>
              </div>
            </div>
            <div className="mr-auto">&nbsp;</div>
            <div className="mr-4 d-block">
              <LanguageSwitcher />
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              {/*
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Regulamin
                </a>
              </li>
              */}
              <li>
                <a href="mailto:kontakt@getpit.pl">Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
