import React from 'react'
// import LanguageSwitcher from '../language-switcher'
import style from './style.module.scss'
import LanguageSwitcher from '../language-switcher'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-auto">&nbsp;</div>
      <div className="mr-4 d-block">
        <LanguageSwitcher />
      </div>
    </div>
  )
}

export default TopBar
